
        <template>
          <div class="vc-snippet-doc">
            <h2>Pagination 分页</h2>
<p>基础的按钮用法，默认状态、鼠标滑过点击、禁止使用。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">style</span>=<span class="hljs-string">"font-size: 16px; margin-bottom: 20px;"</span>&gt;</span>页数<span class="hljs-tag">&lt;<span class="hljs-name">=3页，隐藏翻页及跳转。</span>&lt;/<span class="hljs-attr">div</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">ixu-pagination</span>
            <span class="hljs-attr">:total</span>=<span class="hljs-string">"listPage.total"</span>
            <span class="hljs-attr">:current-page</span>=<span class="hljs-string">"listPage.page"</span>
            @<span class="hljs-attr">current-change</span>=<span class="hljs-string">"pageChange"</span>
            <span class="hljs-attr">:page-size</span>=<span class="hljs-string">"listPage.pagesize"</span>/&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">style</span>=<span class="hljs-string">"font-size: 16px; margin-bottom: 20px; margin-top: 40px;"</span>&gt;</span>页数较多时，显示翻页及跳转。<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">ixu-pagination</span>
            <span class="hljs-attr">:total</span>=<span class="hljs-string">"listPage2.total"</span>
            <span class="hljs-attr">:current-page</span>=<span class="hljs-string">"listPage2.page"</span>
            <span class="hljs-attr">:page-size</span>=<span class="hljs-string">"listPage2.pagesize"</span>
            @<span class="hljs-attr">size-change</span>=<span class="hljs-string">"pageSizeChange"</span>
            @<span class="hljs-attr">current-change</span>=<span class="hljs-string">"pageChange2"</span>/&gt;</span>
        
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
        <span class="hljs-keyword">return</span> {
            <span class="hljs-attr">listPage</span>: {  <span class="hljs-comment">// 分页参数</span>
                    <span class="hljs-attr">total</span>: <span class="hljs-number">26</span>,
                    <span class="hljs-attr">page</span>: <span class="hljs-number">1</span>,
                    <span class="hljs-attr">pagesize</span>: <span class="hljs-number">10</span>
                  },
            <span class="hljs-attr">listPage2</span>: {  <span class="hljs-comment">// 分页参数</span>
                    <span class="hljs-attr">total</span>: <span class="hljs-number">39726</span>,
                    <span class="hljs-attr">page</span>: <span class="hljs-number">1</span>,
                    <span class="hljs-attr">pagesize</span>: <span class="hljs-number">10</span>
                  }
        }
    },
    <span class="hljs-attr">methods</span>: {
        pageChange(page) {
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'currentChange'</span>, page);
            <span class="hljs-keyword">this</span>.listPage.page = page;
        },
        pageSizeChange(size) {
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'sizeChange'</span>, size);
            <span class="hljs-keyword">this</span>.listPage2.page = <span class="hljs-number">1</span>;
            <span class="hljs-keyword">this</span>.listPage2.pagesize = size;
            
        },
        pageChange2(page) {
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'currentChange'</span>, page);
            <span class="hljs-keyword">this</span>.listPage2.page = page;
        },
    },
}
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>background</td>
<td>是否为分页按钮添加背景色</td>
<td>Boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>page-size</td>
<td>每页显示条目个数，支持 .sync 修饰符</td>
<td>number</td>
<td>—</td>
<td>10</td>
</tr>
<tr>
<td>total</td>
<td>总条目数</td>
<td>number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>pager-count</td>
<td>页码按钮的数量，当总页数超过该值时会折叠</td>
<td>number</td>
<td>大于等于 5 且小于等于 21 的奇数</td>
<td>7</td>
</tr>
<tr>
<td>current-page</td>
<td>当前页数，支持 .sync 修饰符</td>
<td>number</td>
<td>—</td>
<td>1</td>
</tr>
<tr>
<td>page-sizes</td>
<td>每页显示个数选择器的选项设置</td>
<td>number[]</td>
<td>—</td>
<td>[10, 20, 30, 40, 50, 100]</td>
</tr>
<tr>
<td>prev-text</td>
<td>替代图标显示的上一页文字</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>next-text</td>
<td>替代图标显示的下一页文字</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>hide-on-single-page</td>
<td>只有一页时是否隐藏</td>
<td>boolean</td>
<td>—</td>
<td>-</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>size-change</td>
<td>pageSize 改变时会触发</td>
<td>每页条数</td>
</tr>
<tr>
<td>current-change</td>
<td>currentPage 改变时会触发</td>
<td>当前页</td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"font-size":"16px","margin-bottom":"20px"}},[_vm._v("页数<=3页，隐藏翻页及跳转。")]),_vm._v(" "),_c('ixu-pagination',{attrs:{"total":_vm.listPage.total,"current-page":_vm.listPage.page,"page-size":_vm.listPage.pagesize},on:{"current-change":_vm.pageChange}}),_vm._v(" "),_c('div',{staticStyle:{"font-size":"16px","margin-bottom":"20px","margin-top":"40px"}},[_vm._v("页数较多时，显示翻页及跳转。")]),_vm._v(" "),_c('ixu-pagination',{attrs:{"total":_vm.listPage2.total,"current-page":_vm.listPage2.page,"page-size":_vm.listPage2.pagesize},on:{"size-change":_vm.pageSizeChange,"current-change":_vm.pageChange2}})],1)}
var staticRenderFns = []

          const exportJavaScript =  {
    data() {
        return {
            listPage: {  // 分页参数
                    total: 26,
                    page: 1,
                    pagesize: 10
                  },
            listPage2: {  // 分页参数
                    total: 39726,
                    page: 1,
                    pagesize: 10
                  }
        }
    },
    methods: {
        pageChange(page) {
            console.log('currentChange', page);
            this.listPage.page = page;
        },
        pageSizeChange(size) {
            console.log('sizeChange', size);
            this.listPage2.page = 1;
            this.listPage2.pagesize = size;
            
        },
        pageChange2(page) {
            console.log('currentChange', page);
            this.listPage2.page = page;
        },
    },
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>